import React, { useState } from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import EyeIcon from '../assests/icons/EyeIcon';
import { encryption } from '../helper';
import axiosInstance from '../api/axiosInstance';
import { toast } from "react-toastify";
import EyeClose from '../assests/icons/EyeClose';

function Login() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLogin] = useState(false);
  const [loginData, setLoginData] = useState({ email: '', password: '' });
  const [validationErrors, setValidationErrors] = useState({
    email: '',
    password: '',
  });

  const handleChange = (e) => {
    setLoginData({ ...loginData, [e.target.name]: e.target.value });
    setValidationErrors({ ...validationErrors, [e.target.name]: null });
  };
  const handleLogin = async (e) => {
    e.preventDefault();
    const encryptedPassword = encryption(loginData.password);
    let errors = {};
    if (!loginData.email) errors.email = 'Email is required';
    if (!loginData.password) errors.password = 'Password is required';

    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
      return;
    }
    try {
      setIsLogin(true);
      const loginResponse = await axiosInstance.post('login', {
        sEmail: loginData.email,
        sPassword: encryptedPassword,
      });
      toast.success(loginResponse.data.sMessage);
      setIsLogin(false);
      localStorage.setItem('token', loginResponse.data.sJwtToken);
      navigate('/');
    } catch (error) {
      setIsLogin(false);
      toast.error(error?.response?.data?.sMessage);
      console.error('error while login', error);
    }
  };

  return (
    <>

      <div className='d-flex flex-grow-1 login align-items-center py-3'>
        <Container>
          <p className='text-center'>
            <a className='logo d-inline-block mb-3 mb-md-4' href='/'>
              Navaratri Passes
            </a>
          </p>
          <Row className='justify-content-center'>
            <Col md={8} lg={5} xxl={4}>
              <Form
                className='m-auto w-100'
                noValidate
                validated={
                  validationErrors.email !== '' || validationErrors.password !== ''
                }
                onSubmit={handleLogin}
              >
                <Form.Group className='mb-4 d-flex flex-column'>
                  <h4>Login</h4>
                  <Form.Text className='m-0 text-muted'>
                    Please provide your login credentials.
                  </Form.Text>
                </Form.Group>
                <Form.Group className='mb-3' controlId='formBasicEmail'>
                  <Form.Label className='fs-6 fw-light lh-1'>Email ID</Form.Label>
                  <Form.Control
                    required
                    type='email'
                    name='email'
                    placeholder='Enter'
                    onChange={handleChange}
                  />

                  {validationErrors.email !== '' && (
                    <Form.Control.Feedback type='invalid'>
                      {validationErrors.email}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>

                <Form.Group className='mb-3' controlId='formBasicPassword'>
                  <Form.Label className='fs-6 fw-light lh-1'>Password</Form.Label>
                  <div className='position-relative'>
                    <Form.Control
                      required
                      type={showPassword ? 'text' : 'password'}
                      name='password'
                      placeholder='Password'
                      onChange={handleChange}
                    />

                    <Form.Control.Feedback type='invalid'>
                      {validationErrors.password}
                    </Form.Control.Feedback>

                    <Button
                      className='position-absolute p-0 bg-transparent border-0 top-50 end-0 translate-middle-y me-2'
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {!showPassword ? <EyeClose/> : <EyeIcon />}
                    </Button>
                  </div>
                </Form.Group>
                <Button
                  variant='primary'
                  disabled={isLoading}
                  type='submit'
                  className='w-100 btn-bg-color fs-6 custom-line-height'
                >
                  Submit
                </Button>
              </Form>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Login;
