import React from 'react';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import LogoutIcon from '../assests/icons/LogoutIcon';
import axiosInstance from '../api/axiosInstance';
import { toast } from 'react-toastify';
import { Button } from 'react-bootstrap';

function Logout() {
  const navigate = useNavigate();
  const handleLogout = async () => {
    try{
      const logoutRes = await axiosInstance.put('logout')
      toast.success(logoutRes?.data?.sMessage)
      localStorage.removeItem('token');
      navigate('/login', { replace: true });
    }catch(error){
      toast.error(error?.response?.data?.sMessage)
      console.error('error while logout', error)
    }
  };
  return (
    <div className='d-none d-md-block logout-btn'>
      <Button
        // className='bg-transparent border-0 text-black'
        bsPrefix='border-0 bg-transparent'
        onClick={handleLogout}
      >
        <LogoutIcon />
        <span>Logout</span>
      </Button>
    </div>
  );
}

export default Logout;
