import React from 'react';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../api/axiosInstance';
import { toast } from 'react-toastify';

function Header() {
  const navigate = useNavigate();
  const handleLogout = async () => {
    try{
      const logoutRes = await axiosInstance.put('logout')
      toast.success(logoutRes?.data?.sMessage)
      localStorage.removeItem('token');
      navigate('/login', { replace: true });
    }catch(error){
      toast.error(error?.response?.data?.sMessage)
      console.error('error while logout', error)
    }
  };
  return (
    <div className='sticky-content d-flex justify-content-between shadow align-items-center'>
      <h2 className='sticky-content-title'>Mehrangi</h2>
      <Button
        className='border-0 bg-transparent d-block d-md-none'
        onClick={handleLogout}
      >
        <svg
          className='d-block d-md-none'
          xmlns='http://www.w3.org/2000/svg'
          width='36'
          height='36'
          viewBox='0 0 36 36'
          fill='none'
        >
          <path
            d='M7.5 31.5C6.675 31.5 5.9685 31.206 5.3805 30.618C4.7925 30.03 4.499 29.324 4.5 28.5V7.5C4.5 6.675 4.794 5.9685 5.382 5.3805C5.97 4.7925 6.676 4.499 7.5 4.5H18V7.5H7.5V28.5H18V31.5H7.5ZM24 25.5L21.9375 23.325L25.7625 19.5H13.5V16.5H25.7625L21.9375 12.675L24 10.5L31.5 18L24 25.5Z'
            fill='black'
          />
        </svg>
      </Button>
    </div>
  );
}

export default Header;
