import React, { useEffect, useState } from 'react';
import Header from './Header';
import { Button, Form, Spinner } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import Logout from './Logout';
import axiosInstance from '../api/axiosInstance';
import { toast } from 'react-toastify';

function BookingDetails() {
  const [bookingDetails, setBookingDetails] = useState({});
  const [loading, setLoading] = useState(false);
  const [claimedTicket, setClaimedTicket] = useState();
  const [claimedTickeError, setClaimedTicketError] = useState('');
  const navigate = useNavigate();
  const { id } = useParams();

  const getBookingDetails = async () => {
    try {
      setLoading(true);
      const bookingDetailsRes = await axiosInstance.get(`ticket-booking/${id}`);
      setBookingDetails(bookingDetailsRes?.data?.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error('error while fetching booking details', error);
    }
  };
  useEffect(() => {
    getBookingDetails();
  }, [id]);

  const handleClaimedTicket = (e) => {
    setClaimedTicket(e.target.value);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    let error = '';
    if (!claimedTicket) error = 'This field is required';

    if (error !== '') {
      return;
    }
    try {
      setLoading(true);
      const claimedTicketRes = await axiosInstance.put(
        `ticket-booking/${bookingDetails.sBookingId}`,
        {
          nArrived: claimedTicket,
        }
      );
      toast.success(claimedTicketRes?.data?.sMessage);
      getBookingDetails();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(error?.response?.data?.sMessage);
      console.error('error while claimed tickets', error);
    }
  };
  return (
    <>
      <Header />
      <div className='booking-detail mt-0 mt-md-4 d-flex flex-column flex-grow-1 pb-4'>
        <div className='row mb-3'>
          <div className='col-lg-12'>
            <div className='boking-detail-head'>
              <div className='row'>
                <div className='col-4 col-md-5 ps-0'>
                  <Button
                    type='button'
                    className='bg-transparent border-0 p-0'
                    onClick={() => navigate(-1)}
                  >
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='32'
                      height='32'
                      viewBox='0 0 32 32'
                      fill='none'
                    >
                      <path
                        d='M25.3334 14.6667H10.4401L16.9467 8.16C17.4667 7.64 17.4667 6.78666 16.9467 6.26666C16.8234 6.14306 16.6769 6.045 16.5156 5.97809C16.3543 5.91118 16.1814 5.87674 16.0067 5.87674C15.8321 5.87674 15.6592 5.91118 15.4979 5.97809C15.3366 6.045 15.1901 6.14306 15.0667 6.26666L6.28006 15.0533C6.15646 15.1767 6.05839 15.3232 5.99149 15.4845C5.92458 15.6458 5.89014 15.8187 5.89014 15.9933C5.89014 16.168 5.92458 16.3409 5.99149 16.5022C6.05839 16.6635 6.15646 16.81 6.28006 16.9333L15.0667 25.72C15.1902 25.8434 15.3367 25.9414 15.498 26.0082C15.6593 26.075 15.8322 26.1094 16.0067 26.1094C16.1813 26.1094 16.3542 26.075 16.5155 26.0082C16.6767 25.9414 16.8233 25.8434 16.9467 25.72C17.0702 25.5966 17.1681 25.45 17.2349 25.2887C17.3017 25.1274 17.3361 24.9546 17.3361 24.78C17.3361 24.6054 17.3017 24.4326 17.2349 24.2713C17.1681 24.11 17.0702 23.9634 16.9467 23.84L10.4401 17.3333H25.3334C26.0667 17.3333 26.6667 16.7333 26.6667 16C26.6667 15.2667 26.0667 14.6667 25.3334 14.6667Z'
                        fill='black'
                      />
                    </svg>
                  </Button>
                </div>
                <div className='col-8 col-md-5 fs-4 fw-bold px-0'>
                  Booking Details
                </div>
              </div>
            </div>
          </div>
        </div>
        {!loading && bookingDetails ? (
          <Form
            validated={claimedTickeError !== ''}
            className='row mt-0 mt-md-5'
            onSubmit={handleSubmit}
          >
            {bookingDetails?.sName && (
              <div className='col-12 col-md-4'>
                <div className='row'>
                  <div className='col-5 col-md-12 title fs-5'>
                    <p>Name</p>
                  </div>
                  <div className='col-7 col-md-12'>
                    <div className='row gx-2 gx-md-4'>
                      <p className='col-1 d-block d-md-none px-0'>:</p>
                      <p className='col-10 col-md-12 fs-4 fw-medium'>
                        {bookingDetails?.sName}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {bookingDetails?.sEmail && (
              <div className='col-12 col-md-4'>
                <div className='row'>
                  <div className='col-5 col-md-12 title fs-5'>
                    <p>Email ID</p>
                  </div>
                  <div className='col-7 col-md-12'>
                    <div className='row gx-2 gx-md-4'>
                      <p className='col-1 d-block d-md-none px-0'>:</p>
                      <p className='col-10 col-md-12 fs-4 fw-medium text-break'>
                        {bookingDetails?.sEmail}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {bookingDetails?.oVenue?.sVenueName && (
              <div className='col-12 col-md-4'>
                <div className='row'>
                  <div className='col-5 col-md-12 title fs-5'>
                    <p>Venue</p>
                  </div>
                  <div className='col-7 col-md-12'>
                    <div className='row gx-2 gx-md-4'>
                      <p className='col-1 d-block d-md-none px-0'>:</p>
                      <p className='col-10 col-md-12 fs-4 fw-medium'>
                        {bookingDetails?.oVenue?.sVenueName}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {typeof bookingDetails?.nRemainingArrival === 'number' ? (
              <div className='col-12 col-md-4'>
                <div className='row'>
                  <div className='col-5 col-md-12 title fs-5'>
                    <p>Number of Tickets</p>
                  </div>
                  <div className='col-7 col-md-12'>
                    <div className='row gx-2 gx-md-4'>
                      <p className='col-1 d-block d-md-none px-0'>:</p>
                      <p className='col-10 col-md-12 fs-4 fw-medium'>
                        {`${bookingDetails?.nArrived}\\${bookingDetails.nQty}`}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}

            {bookingDetails?.oEvent?.dEventDate && (
              <div className='col-12 col-md-4'>
                <div className='row'>
                  <div className='col-5 col-md-12 title fs-5'>
                    <p>Booked for</p>
                  </div>
                  <div className='col-7 col-md-12'>
                    <div className='row gx-2 gx-md-4'>
                      <p className='col-1 d-block d-md-none px-0'>:</p>
                      <p className='col-10 col-md-12 fs-4 fw-medium'>
                        19/10/2023
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {bookingDetails.nRemainingArrival !== 0 && (
              <>
                <div className='col-12 col-md-4'>
                  <div className='row'>
                    <div className='col-5 col-md-12 title fs-5'>
                      <p>Claimed Tickets</p>
                    </div>
                    {bookingDetails.nRemainingArrival !== 0 && (
                      <div className='col-7 col-md-12'>
                        <div className='row'>
                          <p className='col-1 d-block d-md-none px-0'>:</p>
                          <p className='col-10 col-md-12 fs-4 fw-medium'>
                            <Form.Control
                              required
                              type='number'
                              name='claimedTicket'
                              id='claimed-tickets'
                              onChange={handleClaimedTicket}
                            />
                          </p>
                          <Form.Control.Feedback type='invalid'>
                            {'validationErrors.password'}
                          </Form.Control.Feedback>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className='col-12 text-center'>
                  <Button
                    bsPrefix='btn-bg-color arrived text-white rounded'
                    type='submit'
                    disabled={bookingDetails.nRemainingArrival === 0}
                  >
                    Marked as Arrived
                  </Button>
                </div>
              </>
            )}
          </Form>
        ) : (
          <div className='align-items-center d-flex flex-grow-1 justify-content-center'>
            <Spinner animation='border' variant='primary' />
          </div>
        )}
      </div>
      <Logout />
    </>
  );
}

export default BookingDetails;
