import React from 'react';
import { Button } from 'react-bootstrap';
import Header from './Header';
import Logout from './Logout';
import { Link, useNavigate } from 'react-router-dom';

function Home() {
  const navigate = useNavigate();
  const handleScan = () => {
    navigate('/scan');
  };
  return (
    <>
      <Header />
      <div className='d-flex flex-column flex-grow-1'>
        <div className='text-center my-auto'>
          <div>
            <Button
              variant='primary'
              type='submit'
              className='btn-bg-color fs-6 custom-line-height'
              onClick={handleScan}
            >
              Scan QR
            </Button>
          </div>
          <p className='mx-auto w-75 qr-text mt-5 mb-0'>
            To verify the ticket, please scan the QR code by clicking on the
            above button.
          </p>
        </div>
      </div>
      <Logout />
    </>
  );
}

export default Home;
