import React from 'react'

function LogoutIcon() {
  return (
    <svg
    xmlns='http://www.w3.org/2000/svg'
    width='36'
    height='36'
    viewBox='0 0 36 36'
    fill='none'
  >
  <path
    d='M7.5 31.5C6.675 31.5 5.9685 31.206 5.3805 30.618C4.7925 30.03 4.499 29.324 4.5 28.5V7.5C4.5 6.675 4.794 5.9685 5.382 5.3805C5.97 4.7925 6.676 4.499 7.5 4.5H18V7.5H7.5V28.5H18V31.5H7.5ZM24 25.5L21.9375 23.325L25.7625 19.5H13.5V16.5H25.7625L21.9375 12.675L24 10.5L31.5 18L24 25.5Z'
    fill='black'
  />
</svg>
  )
}

export default LogoutIcon
