import React from 'react';

function EyeClose() {
  return (
    <svg
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M8.40996 7.23225C8.73545 7.55763 8.73554 8.08527 8.41016 8.41077C8.2539 8.56708 8.16614 8.77907 8.16618 9.00009C8.16622 9.22112 8.25406 9.43308 8.41037 9.58934C8.56669 9.7456 8.77868 9.83336 8.9997 9.83332C9.22073 9.83329 9.43268 9.74545 9.58894 9.58913C9.91432 9.26364 10.442 9.26354 10.7675 9.58892C11.093 9.9143 11.093 10.4419 10.7677 10.7674C10.2989 11.2364 9.66305 11.4999 9 11.5C8.33694 11.5001 7.701 11.2368 7.23207 10.7681C6.76314 10.2993 6.49963 9.66344 6.49951 9.00039C6.49939 8.33734 6.76268 7.7014 7.23144 7.23246C7.55682 6.90697 8.08446 6.90688 8.40996 7.23225Z'
        fill='#53514F'
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M8.99781 4.8327C8.54474 4.83154 8.09271 4.87624 7.64864 4.96614C7.19755 5.05746 6.75785 4.76581 6.66653 4.31472C6.57521 3.86363 6.86686 3.42393 7.31795 3.33261C7.87192 3.22046 8.43582 3.16466 9.00103 3.16604C9.00139 3.16604 9.00175 3.16604 9.00211 3.16604L8.99996 3.99937V3.16604C9.00032 3.16604 9.00067 3.16604 9.00103 3.16604C12.3802 3.16644 15.115 5.07144 17.2145 8.57063C17.3729 8.83453 17.3729 9.16422 17.2145 9.42812C16.6336 10.3963 16.0041 11.2438 15.3239 11.9635C15.0078 12.2979 14.4803 12.3128 14.1459 11.9967C13.8114 11.6805 13.7965 11.1531 14.1127 10.8186C14.6002 10.3028 15.0698 9.69742 15.5191 8.99931C15.6086 9.13854 15.6974 9.28146 15.7854 9.42812L16.5 8.99937L15.7854 8.57063C15.6974 8.71723 15.6086 8.86011 15.5191 8.99931C13.6746 6.1318 11.499 4.83271 8.99996 4.83271L8.99781 4.8327ZM5.8084 4.66556C6.05075 5.05682 5.93003 5.57047 5.53877 5.81282C4.4451 6.49024 3.42092 7.53854 2.48085 8.99942C4.3253 11.8669 6.50093 13.166 8.99996 13.166H9.00546V13.1661C10.2262 13.1741 11.4239 12.8346 12.4588 12.1871C12.849 11.943 13.3631 12.0614 13.6072 12.4515C13.8514 12.8417 13.733 13.3559 13.3428 13.6C12.0405 14.4148 10.5333 14.8423 8.99724 14.8327C5.61886 14.8317 2.88456 12.9267 0.785382 9.42812C0.627041 9.16422 0.627041 8.83453 0.785382 8.57063C1.89347 6.72382 3.17898 5.31399 4.66115 4.39593C5.05241 4.15358 5.56605 4.2743 5.8084 4.66556Z'
        fill='#53514F'
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M0.910704 0.910093C1.23614 0.584656 1.76378 0.584656 2.08922 0.910093L17.0892 15.9101C17.4147 16.2355 17.4147 16.7632 17.0892 17.0886C16.7638 17.414 16.2361 17.414 15.9107 17.0886L0.910704 2.0886C0.585267 1.76317 0.585267 1.23553 0.910704 0.910093Z'
        fill='#53514F'
      />
    </svg>
  );
}

export default EyeClose;
