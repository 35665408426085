import './App.css';
import { Route, Routes } from 'react-router-dom';
import Home from './component/Home';
import Login from './component/Login';
import BookingDetails from './component/BookingDetails';
import Scan from './component/Scan';
import './api/interceptors';
import ProtectedRoute from './component/ProtectedRoute';
import PublicRoute from './component/PublicRoute';
import { ToastContainer } from 'react-toastify';

function App() {
  return (
    <main>
      <Routes>
        <Route element={<ProtectedRoute />}>
          <Route index path='/' element={<Home />} />
          <Route path='/scan' element={<Scan />} />
          <Route path='/booking-details/:id' element={<BookingDetails />} />
        </Route>

        <Route element={<PublicRoute />}>
          <Route path='/login' element={<Login />} />
        </Route>
      </Routes>
      <ToastContainer />
    </main>
  );
}

export default App;
