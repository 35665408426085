
import axiosInstance from './axiosInstance'

axiosInstance.interceptors.request.use(
  (config) => {
    // Modify the request config, add headers, or perform other tasks
    const token = localStorage.getItem('token');
    // If a token exists, add it to the Authorization header
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    config.headers.test = 'hello'
    return config;
  },
  (error) => {
    console.log('interceptors error', error)
    return Promise.reject(error);
  }
);

